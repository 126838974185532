import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './404.module.css';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page not found" />
      <div className={styles.content}>
        <h1>Page not found</h1>
        <p>This page does not exist.</p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
